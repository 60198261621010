import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuardService } from "@portal/core";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import("./welcome/welcome.module").then((module) => module.WelcomeModule),
    data: { animationState: "One" },
  },
  // { path: 'employeeadmin', loadChildren: () => import('./employeeadmin/employeeadmin.module').then(module => module.EmployeeAdminModule), data:{animationState:'Two'} },
  {
    path: "admin",
    loadChildren: () => import("./admin/admin.module").then((module) => module.AdminModule),
    data: { animationState: "Two" },
    canActivate: [AuthGuardService],
  },
  {
    path: "error",
    loadChildren: () => import("@portal/logger").then((module) => module.LoggerModule),
    data: { animationState: "Three" },
  },
  { path: "**", redirectTo: "error", data: { animationState: "Four" } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
