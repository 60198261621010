import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'zx-preloader',
  template: `
    <div class="loader {{active}}">
      <div class="cube-grid">
        <div class="cube cube1"></div>
        <div class="cube cube2"></div>
        <div class="cube cube3"></div>
        <div class="cube cube4"></div>
        <div class="cube cube5"></div>
        <div class="cube cube6"></div>
        <div class="cube cube7"></div>
        <div class="cube cube8"></div>
        <div class="cube cube9"></div>
      </div>
    </div>
  `,
  styleUrls: ['./zx-preloader.component.scss']
})
export class ZxPreloaderComponent implements OnInit {
  public active: string = '';

  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>this.active = 'active',100);
  }

}
