import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { tap, catchError } from "rxjs/operators";
import { Observable, of} from 'rxjs';

import { ZxCaching } from '../service/caching/zx-caching.service';

@Injectable()
export class ZxInterceptor implements HttpInterceptor {

  constructor(private ZxCaching:ZxCaching)  {}

  intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<any> {
    let ZxCaching =  this.ZxCaching.get(req);
    return  ZxCaching ? of(ZxCaching) : this.farword(req, next);
  }

  farword(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap(event => !(event instanceof HttpResponse) || req.method != 'GET' || this.ZxCaching.add(req, event)));
  }
}
