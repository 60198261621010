import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef
} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZxDOM {

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  /**
   * Create component reference
   * @param component - component
   * @param componentProps - component properties
   */
  createComponent(component: any, componentProps?: object) {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    if (componentProps && typeof componentRef.instance === 'object') {
      Object.assign(componentRef.instance as object, componentProps);
    }
    return componentRef;
  }

  /**
   * Attach component to specific element
   * @param componentRef - component reference
   * @param appendTo - append component to this element
   */
  attachComponent(componentRef: ComponentRef<unknown>, appendTo: Element) {
    /** Attach component to Application Reference so it's inside ng Component tree */
    this.appRef.attachView(componentRef.hostView);

    /** Get DOM element from Component Reference */
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    /** Append DOM Element to specific element */
    appendTo.appendChild(domElem);
    return;
  }
}
