import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'lib-logger',
  template: `
     <div class="error-page">
       <div class="error-code">
         <span>4o3</span>
       </div>
       <div class="error-message">
         <h1>Sorry...</h1>
         <p>You do not have permission to view this page.<br />
         Let's find a better place for you to go.</p>
         <ul>
           <li><a (click)="goBack()">Go Back</a></li>
           <li><a [routerLink]="['/']">Go Home</a></li>
           <li><a [routerLink]="['/contact-us']">Contact Us</a></li>
         </ul>
       </div>
     </div>
  `,
  styleUrls: ['../logger.component.scss']
})
export class Error403Component implements OnInit {

  constructor(private location:Location) { }

  ngOnInit(): void {
  }

  goBack(){
    this.location.back();
  }

}


