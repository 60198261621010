import { Injectable } from '@angular/core';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class ZxUserService {
  public logOut!:()=> any;
  private _user:UserModel|any = {};
  private _permissions:string[]=[];
  public isRedirect:any='';

  set add(user:UserModel){
    this._user = new UserModel(user);
    this._permissions = user.permissions||[]
    this._permissions.push('EXPAND_MENU')
  }

  set token(token:AccessToken|any) {
    localStorage.setItem('token', token.token_type +' '+ token.access_token);
    this.otp = token.mfaRequired;
  }

  set otp(mfaRequired:boolean|any) {
     localStorage.setItem('otp', Md5.hashStr(mfaRequired ? `required ${this.token}` : `valid ${this.token}`).toString());
  }

  get type(){
    return this._user.accountType;
  }

  get token(){
    return localStorage.getItem('token');
  }

  get id(){
    return this._user.id;
  }

  get firstName(){
    return this._user.firstName;
  }

  get lastName(){
    return this._user.lastName;
  }

  get fullName(){
    return this._user.firstName + " " + this._user.lastName;
  }

  get email(){
    return this._user.email;
  }

  get companyName(){
    return this._user.companyName;
  }

  get occupation(){
    return this._user.occupation;
  }

  get phone(){
    return this._user.phone;
  }

  get status(){
    return this._user.status;
  }

  get verified(){
    return this._user.verified;
  }

  get employee(){
    return this._user.employee;
  }

  get enrolled(){
    return this._user.enrolled;
  }

  get active(){
    return this._user.active || this._user.status === 'Active';
  }

  get passwordExpiresIn(){
    return this._user.passwordExpiresIn >= 1 ? (this._user.passwordExpiresIn > 1 ? 'Your password will expire in '+ this._user.passwordExpiresIn+' days.' : ' Your password will expire Today.') : 'Your password has expired.';
  }

  get passwordExpire(){
    return this._user.passwordExpiresIn;
  }

  get isActivePermisions() {
    return this._permissions.length;
  }

  cleanPasswordExpire(){
    this._user.passwordExpiresIn = undefined;
  }

  get otp():any{
    return localStorage.getItem('otp') === Md5.hashStr(`valid ${this.token}`).toString()
           ? true : localStorage.getItem('otp') === Md5.hashStr(`required ${this.token}`).toString()
           ? false : this.logOut();
  }

  hasPermissin(permission:string):boolean{
    //if(permission === 'CUSTOMER_IDEA') return true;
    return this._permissions.includes(permission);
  }

  get logged(){
    return !!this?.id && this.otp;
  }

  get isLogged(): Promise<boolean> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(!!this?.id && this.otp && this.isActivePermisions);
      }, 500);

    });
  }

  clearUser(){
    this._user = {};
    delete localStorage['token'];
    delete localStorage['otp'];
  }
}


export class UserModel {
  id?:string;
  firstName:string;
  lastName:string;
  email:string;
  password?:string;
  confirmPassword?:string;
  companyName:string;
  occupation:string;
  phone?:string;
  terms?:string;
  status?:string;
  verified?:boolean;
  employee?:boolean;
  enrolled?:boolean;
  active?:boolean;
  permissions?:string[];
  passwordExpiresIn?:number|null;
  accountType?:string;

  constructor(user:UserModel|any){
    this.id = user.id;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.companyName = user.companyName;
    this.occupation = user.occupation||user.occupationCode;
    this.phone = user.phone;
    this.terms = user.terms;
    this.employee = user.employee;
    this.status = user.status;
    this.verified = user.verified;
    this.enrolled = user.enrolled;
    this.active = user.active;
    this.passwordExpiresIn = user.passwordExpiresIn;
    this.accountType = user.accountType;
  }
}

export interface AccessToken {
  mfaRequired:boolean;
  access_token:string;
  token_type:string;
}
