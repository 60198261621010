// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// Locale imports
import bs from "@angular/common/locales/bs";
import de from "@angular/common/locales/de";
import en_GB from "@angular/common/locales/en-GB";

export const environment = {
  production: false,
  host: "/api",
  cache: { enabled: false, ttl: 600 }, //600 10min
  logging: false,
  language: "en-gb",
  languages: [
    /////////////// Localization list ///////////////////////////////
    { name: "Bosanski", id: "bs", locale: bs },
    { name: "English", id: "en-gb", locale: en_GB },
    { name: "German", id: "de", locale: de },
    /////////////// Localization list end here ////////////////////////////
  ],
  appId: "PORTAL",
  appName: "Portal",
  appDescription: "ZIRA Partner Portal",
  appLogo: "/assets/zira-logo.png",
  appIcon: "/assets/virgin-red-white.png",
};
