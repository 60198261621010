export class NavigationModel {
	public code:string;
	public items?:NavigationItem[];
	public url?:string;
	public expand:boolean;

	constructor(cfg:any) {
		this.code = cfg.code;
		this.items = cfg.items;
		this.url = cfg.url;
		this.expand = cfg.expand;
	}

	toggle(){
		this.expand = !this.expand;
	}
}

export class NavigationItem {
	public code:string;
	public label:string;
	public description?:string;
	public icon:string;
	public active:boolean;
	public children?:NavigationItem[];
	public action?:string;
	public url?:string;
  public hidden:boolean;

	constructor(cfg:any) {
		this.code = cfg.code;
		this.label = cfg.label;
		this.description = cfg.description;
		this.action = cfg.action;
		this.icon = cfg.icon;
		this.children = cfg.children||[];
		this.active = cfg.active;
		this.url = cfg.url;
    this.hidden = cfg.hidden;
	}
}
