import { Injectable } from '@angular/core';
import { NavigationModel, NavigationItem } from './navigation.model';
import { Router } from '@angular/router';
import { ZxApi } from '@portal/core'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private navi:any = {};
  private code:string = "";

  constructor(private api:ZxApi, private router:Router) {}

  set menu(menu:NavigationModel){
    this.code = menu.code;
    if(!this.navi[menu.code]) {
      this.navi[menu.code] = menu;
      this.api.get(menu.url).subscribe(res=>{ 
        menu.items = res.payload as NavigationItem[]; 
        menu.items.forEach((item:NavigationItem)=>{ if(item.url && this.router.url.includes(item.url)) item.active = true});
      });
    } else {
      this.navi[menu.code].items.forEach((item:NavigationItem)=>{ if(item.url && this.router.url.includes(item.url)) item.active = true});
    }
  }

  get menu():any {
    return this.navi[this.code];
  }

  get items():any {
    return this.navi[this.code].items;
  }

  get actions():any[] {
    return this.navi[this.code].items.filter((v:NavigationItem)=> v.active);
  }

  get links():any[] {
    return this.navi[this.code].items.filter((v:NavigationItem)=> v.url);
  }

  get homeMenu():any[] {
    return this.navi[this.code]?.items?.filter((v:any)=>v.url && !/HOME_PAGE/.test(v.code));
  }

  get active():any {
    return this.navi[this.code]?.items?.find((v:NavigationItem)=> v.url && v.active);
  }  

  set active(item:NavigationItem){
    this.navi[this.code].items.filter((v:NavigationItem) => v.url).map((v:NavigationItem)=>{v.active = false});
    item.active = true;
  }

  goTo(item:NavigationItem){
    this.active = item;
    this.router.navigate([item.url])
  }

  childrenGoTo(item:NavigationItem){
    this.router.navigate([item.url])
  }

  navigate(url:string){
    this.navi[this.code].items.forEach((item:NavigationItem)=>item.active = !!item.url && item.url.includes(url));
    this.router.navigate([url]);
  }

}
