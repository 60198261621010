import { Injectable, Inject, LOCALE_ID, ApplicationRef } from '@angular/core';
import { ZxApi } from '../api/zx-api.service';
import { ZxUserService } from '../user/zx-user.service';
import { Md5 } from 'ts-md5/dist/md5';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ZxTranslate {
  public _locale:string;
  public locales?:any[];
  private loadedLang:any = {config:{}};
  public language:any = {};


  constructor(private api: ZxApi,  @Inject('options') private options:any, private user:ZxUserService, private message:ToastrService)  {
    this._locale = localStorage.getItem('language') || options.language;
    this.languages = options.languages;
    //this.load(this._locale);
  }

  set languages(languages){
    this.locales=languages||[];
  }

  get languages(){
    return this.locales||[];
  }

  get locale(){
    return this._locale;
  }

  get production(){
    return this.options.production;
  }

  get diff(){
    return  Object.keys(this.language).filter(v=> !this.loadedLang.config[v]).length;
  }

  public use(lang:string){
    localStorage.setItem('language', lang.toLowerCase());
    window.location.reload();
  }

  public useDefault(lang:string){
    if(!localStorage.getItem('language')) {
      localStorage.setItem('language', lang.toLowerCase());
      window.location.reload();
    }
  }

  public translate(key:string){
    let index = Md5.hashStr((key || "").toLowerCase().trim().replace(/\s\s+/g, " ")).toString();
    if(!this.language[index] && key) this.language[index] = key;
    return this.language[index]||key;
  }

  public list(items:string[]){return items.map((v:string)=> v = this.translate(v));}

  public save(diff?:any){
    let entity = {appId: 'ALL_APP', code:this._locale, category: 'language', name:'Language', ...this.loadedLang, config:this.language};
    this.api[this.loadedLang.id?'put':'post']('/commonregistry/gui-configuration', { entity } ).subscribe((lang:any)=> {
      this.message.success(this.translate('Language is updated successfully'));
      this.setLanguage(lang);
      !diff||diff(this.diff);
    });
  }

  public default(){
    //Go to uaa as user default lagnguageCode
  }

  public async load(){
    await this.api.get(`/commonregistry/gui-configuration/code/${this._locale}`).toPromise().then((lang:any)=> this.setLanguage(lang), (error)=>this.setLanguage({}));
  }

  private setLanguage(lang:any){
    this.loadedLang = lang.payload||{config:{}};
    this.language = {...this.loadedLang.config};
  }
}
