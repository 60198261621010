import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ZxUserService } from "./zx-user.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(private user: ZxUserService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>(observer => { 
      this.user.isRedirect='';
      this.user.isLogged.then((logged:any) => {
        if (!logged) {
          if (state.url.includes("admin/incidents?redirect=true")) {
            this.user.isRedirect ='admin/incidents?redirect=true';
            observer.next(this.router.createUrlTree(['/']));
          } else {
            observer.next(this.router.createUrlTree(["error", "403"]));
          }
        } else if (!route.data["permission"] || this.user.hasPermissin(route.data["permission"])) {
          observer.next(true);
        } else if( state.url.includes("admin/incidents?redirect=true") && !this.user.hasPermissin('CUSTOMER_OUTAGE_NOTIFICATION_CHECK')) {
          this.router.navigate(['admin']);
        } else {
          observer.next(this.router.createUrlTree(["error", "403"]));
        }
  
        observer.complete();
      });
    });
  }
}
