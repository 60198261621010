import { Pipe, PipeTransform } from '@angular/core';
import { ZxTranslate } from '../service/translate/zx-translate.service';

@Pipe({name: 'translate'})
export class Translate implements PipeTransform {

  constructor(private lang: ZxTranslate){}

  transform(input:any) : any{
  	return this.lang.translate(input);
  }

}
