import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import {ZxTranslate} from "../service/translate/zx-translate.service";

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective {
  @Input() tooltip: string|undefined;
  @Input() tooltipClass!: string;
  @Input() tooltipHeader!: string;
  @Input() tooltipIcon!: string;
  @Input() tooltipType: string = 'info';
  @Input() tooltipPosition: string = 'top';
  @Input() delay = 500;
  @Input() showOnClick: boolean = false;
  public tooltipElement!: HTMLElement|any;
  public offset = 10;

  constructor(private el: ElementRef, private translateService: ZxTranslate) {
    this.tooltipElement = document.createElement('div');

  }

  @HostListener('click') onClick() {
    if (this.tooltipElement && this.tooltip && this.showOnClick) this.show();
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.tooltipElement && this.tooltip && !this.showOnClick) this.show();
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.tooltipElement)  this.hide();
  }

  show() {
    this.create();
    this.setPosition();
    //this.renderer.addClass(this.tooltipElement, 'show');
    this.tooltipElement.classList.add('show');
  }

  hide() {
    //document.body.classList.remove('show') //.removeClass(this.tooltipElement, 'show');
    this.tooltipElement.classList.remove('show');
    setTimeout(()=>this.tooltipElement.remove(), 500);
    //this.renderer.removeChild(, t);
    //document.body.removeChild(this.tooltipElement);
    //this.tooltipElement = null;
  }

  create() {
    
    let inner = '';
    inner += this.tooltipIcon ? `<i class="icon ${this.tooltipIcon}"></i>` : '';
    inner += '<div class="wrapper">';
    inner += this.tooltipHeader ? `<div class="header">${this.translateService.translate(this.tooltipHeader)}</div>` : '';
    inner += this.tooltip ? `<div class="body">${this.translateService.translate(this.tooltip)}</div>` : '';
    inner += '</div>';
    
    this.tooltipElement.innerHTML = inner;

    //this.renderer.appendChild(, );
    document.body.append(this.tooltipElement);

    this.tooltipElement.classList.add('tooltip');
    //this.renderer.addClass(this.tooltipElement, );
    if (this.tooltipClass) this.tooltipElement.classList.add(this.tooltipClass);
    this.tooltipElement.classList.add(this.tooltipType);
    this.tooltipElement.classList.add(this.tooltipPosition);

/*    this.tooltipElement.style['-webkit-transition'] = `opacity ${this.delay}ms`;
    this.tooltipElement.style['-moz-transition'] = `opacity ${this.delay}ms`;
    this.tooltipElement.style['-o-transition'] = `opacity ${this.delay}ms`;*/
    this.tooltipElement.style['transition'] = `opacity ${this.delay}ms`;
/*    this.renderer.setStyle(this.tooltipElement, '-webkit-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltipElement, '-moz-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltipElement, '-o-transition', `opacity ${this.delay}ms`);
    this.renderer.setStyle(this.tooltipElement, 'transition', `opacity ${this.delay}ms`);*/
  }

  setPosition() {
    const hostPos:any = this.el.nativeElement.getBoundingClientRect();
    const tooltipPos:any = this.tooltipElement ? this.tooltipElement.getBoundingClientRect():{};
    const scrollPos:any = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    let top, left;

    if (this.tooltipPosition === 'top') {
      top = hostPos.top - tooltipPos.height - this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.tooltipPosition === 'bottom') {
      top = hostPos.bottom + this.offset;
      left = hostPos.left + (hostPos.width - tooltipPos.width) / 2;
    }

    if (this.tooltipPosition === 'left') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.left - tooltipPos.width - this.offset;
    }

    if (this.tooltipPosition === 'right') {
      top = hostPos.top + (hostPos.height - tooltipPos.height) / 2;
      left = hostPos.right + this.offset;
    }

    this.tooltipElement.style.top = `${top + scrollPos}px`;
    this.tooltipElement.style.left = `${left}px`;

/*    this.renderer.setStyle(this.tooltipElement, 'top', `${top + scrollPos}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);*/
  }
}
