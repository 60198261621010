import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ZxPreloaderService {
  public isLoading = new BehaviorSubject(false);
  public list: any = {};
  constructor() { }
}
