import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import {ZxAction, ZxUserService} from '@portal/core'

import { NavigationService } from './navigation.service'
import { NavigationModel, NavigationItem } from './navigation.model'

@Component({
  selector: 'navigation',
  template: `
    <ul class="navigation">
      <ng-container *ngFor="let item of navi.items">
        <li [ngClass]="{active: item.active, action:item.action, hidden:item.hidden}" title="{{item.label}}" (click)="execute(item)" *ngIf="user.hasPermissin(item.code)">
          <i class="{{item.icon}}"></i>
        </li>
      </ng-container>

    </ul>
  `,
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  [x:string]:any;

  @Input() config!:NavigationModel;

  constructor(public navi:NavigationService, private action:ZxAction, public user: ZxUserService) { }

  ngOnInit(): void {
    this.navi.menu = this.config;
  }

  execute(item:NavigationItem){
    if(this.action['BEFORE_NAVIGATE'] && item.url) return this.action['BEFORE_NAVIGATE'](item);
    if(item.action && this.action[item.action]) return this.action[item.action](item);
    if(item.action && this[item.action]) return this[item.action](item);
    if(item.url) this.navi.goTo(item)
  }

  expand(item:NavigationItem) {

  }
}



