import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'orderBy'})
export class OrderBy implements PipeTransform {

  transform(input:any, index?:string, by:string="ASC") : any{
      if(!Array.isArray(input) || input.length <= 1 || !index) return input;
      let i = index.split("."); input.sort((a:any, b:any)=> {let x = this.mapval(a, i);let y = this.mapval(b, i); return x < y ? -1 : x > y ? 1 : 0;});
      return by == "ASC" ? input : input.reverse();
  }

  private mapval(input:any, index:string[]):string {let value : any; index.map((v:string)=> value = value&&value[v]||input[v]); return value;}

}
