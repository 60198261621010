import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from '@portal/core';
import { LoggerModule } from '@portal/logger';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment'
import { XFrameOptionsInterceptor } from 'projects/portal/core/src/lib/service/x-frame-options.interceptor/x-frame-options.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.options(environment),
    LoggerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XFrameOptionsInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }