import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoggerComponent } from './logger.component';
import { Error404Component } from './component/404.component';
import { Error403Component } from './component/403.component';

const routes: Routes = [
  { path: '', component:Error404Component },
  { path: '404', component:Error404Component },
  { path: '403', component:Error403Component }
];


@NgModule({
  declarations: [
    LoggerComponent,
    Error404Component,
    Error403Component
  ],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    LoggerComponent,
    Error404Component,
    Error403Component
  ]
})
export class LoggerModule { }



