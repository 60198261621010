export class AppHeaderConfig {
	public appId:string;
	public appName:string;
	public appDescription?:string;
	public appLogo?:string;
	public appIcon?:string;
	public _user?:any;
	public actions?:ActionConfig[];

	constructor(cfg:any){
		this.appId = cfg.appId;
		this.appName = cfg.appName;
		this.appDescription = cfg.appDescription;
		this.appLogo = cfg.appLogo;
		this.appIcon = cfg.appIcon;
		this.actions = (cfg.actions||[]).map((v:any)=> new ActionConfig(v));

		this.actions?.push(new ActionConfig({type:'LOGOUT', label:'Log out', action:'logout'}));
	}

	set user(user:any){
		this._user = user;
	}

	get user(){
		return this._user;
	}
}

export class ActionConfig {
	public id:string;
	public label:string;
	public type:'ALARM'|'TASK'|'MESSAGE'|'CALENDAR'|'CLIPBOARD'|'ALL'|'LOGOUT'|'LOGIN'|'SIGNIN';
	public icon?:string;
	public action:any
	public hidden?:boolean;
	public disabled?:boolean;
	public _badges?:number;
	public path?:string;

	constructor(cfg:any){
		this.id = cfg.id;
		this.label = cfg.label;
		this.type = cfg.type;
		this.icon = cfg.icon||icons[cfg.type];
		this.action = cfg.action;
		this.hidden = cfg.hidden;
		this.disabled = cfg.disabled;
		this.path = cfg.path;
	}

	set badges(value:number) {
    	this._badges = value;
  	}

  	get badges():any{
    	return this._badges;
  	}
}

const icons:any = {
  ALARM: 'fal fa-bell',
  TASK: 'fal fa-tasks',
  MESSAGE: 'fal fa-envelope',
  CALENDAR: 'fal fa-calendar',
  CLIPBOARD: 'fal fa-paste',
  ALL: 'fal fa-comment-alt-lines',
  LOGOUT: 'fa-duotone fa-power-off',
  ABSENCE: 'fal fa-address-book',
  LOGIN:'fal fa-right-to-bracket',
	SIGNIN:'fal fa-person-to-portal'
}