import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Md5 } from 'ts-md5/dist/md5';

@Injectable({
  providedIn: 'root'
})
export class ZxCaching {
  public enabled;
  private CacheItmes:any = {};
  private TTLCacheMap:TTLCacheMap= {};
  private TTL:number;

  constructor(@Inject('options') private options:any){
    this.TTL = (this.options.cache&&this.options.cache.ttl||300)*1000;
    this.enabled = this.options.cache&&this.options.cache.enabled;
  }

  public add(req: HttpRequest<any>, response: HttpResponse<any>) : void {
    let body = {...response.body, transactionId: undefined};
    !this.enabled || req.headers.get('caching') == 'false'|| this.set(this.Hash(req.urlWithParams), Date.now()+this.TTL, this.Hash(body), response);
  }

  public get(req: HttpRequest<any>) : HttpResponse<any> | undefined  {
    if(!this.enabled) return undefined;
    let request = this.CacheItmes[this.Hash(req.urlWithParams)]||undefined; this.clearExpaired();
    return request && request.cache ? request.ttl >  Date.now() ? request.response : undefined : undefined;
  }

  public clear(){this.CacheItmes = {}; this.TTLCacheMap = {}; return true;}

  public Hash(item:any) : string {
    return Md5.hashStr(typeof item  == ('number'||'string') ? item : JSON.stringify(item)).toString();
  }

  private set(request:string, ttl:number, sum:string, response:any){
    this.CacheItmes[request] = this.CacheItmes[request] && this.CacheItmes[request].sum == sum ? {sum, ttl, cache:true, response} : {sum, ttl, cache:false, response:undefined};
    this.TTLCacheMap[request] = ttl;
  }

  private clearExpaired(){
    let now = Date.now();
    for(let index in this.TTLCacheMap){
      if(this.TTLCacheMap[index] < now){
        delete this.CacheItmes[index];
        delete this.TTLCacheMap[index];
      }
    };
  }
}

export interface Cache {
  [key:string]:CacheValue;
}

export interface TTLCacheMap {
  [key:string]:number;
}

export interface CacheValue {
  sum:string;
  cache:boolean;
  response:HttpResponse<any>;
  ttl:number;
}

