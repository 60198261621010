import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable, BehaviorSubject } from "rxjs";
import {finalize} from "rxjs/operators";
import {ZxPreloaderService} from "../service/preloader/zx-preloader.service";

@Injectable()
export class ZxPreloaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loading: ZxPreloaderService) { }

  removeRequest(req: HttpRequest<any>) {
    this.requests.indexOf(req) < 0 || this.requests.splice(this.requests.indexOf(req), 1);
    this.setGuidLoading(req, this.requests)||setTimeout(() => this.loading.isLoading.next(this.requests.length > 0),10);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get('loading') || req.headers.get('loading') == 'true'){
      this.requests.push(req);
      this.setGuidLoading(req)||setTimeout(() => this.loading.isLoading.next(true));
    }
    return next.handle(req).pipe(finalize(() => this.removeRequest(req)));
    // return Observable.create(observer => {
    //   const subscription = next.handle(req).subscribe(
    //     (evt) => { !(evt instanceof HttpResponse)||observer.next(evt); },
    //     (err) => { this.removeRequest(req); observer.error(err); },
    //     () =>  { this.removeRequest(req); observer.complete(); }
    //   );
    //   return () => subscription.unsubscribe();
    // });
  }

  setGuidLoading(req: any, requests?:any){
    let LoadingID = req.headers.get('Loading-ID');
    if(!LoadingID) return false;
    this.loading.list[LoadingID] =  new BehaviorSubject(null);
    this.loading.list[LoadingID].next(requests ? false : true);
    return true;
  }
}
