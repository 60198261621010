import { Component, OnInit, Input } from '@angular/core';
import { AppHeaderConfig, ActionConfig } from './app-header.config'
import { Router } from '@angular/router';

import { ZxUserService, ZxAction } from '@portal/core'

@Component({
  selector: 'app-header',
  template: `
    <div class="app-header">
      <div class="left-side">
        <img *ngIf="config.appLogo" [src]="config.appLogo" [alt]="config.appName">
        <span *ngIf="config.appName">{{config.appName}}
          <small *ngIf="config.appDescription">{{config.appDescription}}</small>
        </span>
        <ng-content select="[left-side]"></ng-content>
      </div>
      <div class="right-side">
        <div class="welcome" *ngIf="config?.user.fullName">
          <span (click)="toggleUserInfo();"><i class="fal fa-user mr-10"></i>{{config.user.fullName}}</span>
        </div>
          <div class="btn-icon" *ngFor="let action of config.actions" (click)="exec(action)" title="{{action.label}}" >
            <i class="{{action.icon}}"></i>
            <div class="count" *ngIf="action.badges">{{action.badges}}</div>
          </div>
      </div>
      <img src="assets/bg/header.svg" class="background" alt="background">
    </div>
  `,
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  [x: string]: any;
  @Input() config!:AppHeaderConfig;

  constructor(public user:ZxUserService, private action:ZxAction, private route:Router) { }

  ngOnInit(): void {
  }

  exec(option:ActionConfig){
    if(option.action) {
      return typeof option.action === 'function' ? option.action(option) :
      typeof this.action[option.action] === 'function' ? this.action[option.action](option) :
      typeof this[option.action] !== 'function' || this[option.action](option);
    }
    !option.path||this.route.navigate([option.path]);
  }

  toggleUserInfo(){
    this.action['toggleUserInfo']();
  }

  logout(option:ActionConfig) {
    this.config.user.logOut();
    //this.api.get('/logout-user-redirect').subscribe(res => window.location.href = res.url);
  }
}
