import { HttpClient, HttpRequest, HttpHeaders} from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';

import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ZxUserService } from '../user/zx-user.service';

import { ToastrService } from 'ngx-toastr';
import * as queryParse from 'query-string';
//import { Loading } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class ZxApi {
    [x: string]:any;
    constructor(private http: HttpClient, private message:ToastrService, @Inject('options') private options:any, private user:ZxUserService)  {}

    public get(...request:any): Observable<any> {
        let [ path, query, body, options ] = this.setOptions(request);
        return this.http.get(this.query(this.env(path), query, body), options).pipe(catchError( error => {
            //this.message.error(error.error.message||error.message);
            this.isAuthenticated(error);
            return throwError(error);
        }));
    }

    public post(...request:any): Observable<any> {
        let [ path, body, query, options ] = this.setOptions(request);
        return this.http.post(this.query(this.env(path), query), body, options).pipe(catchError( error => {
            //this.message.error(error.error.message||error.message);
            this.isAuthenticated(error);
            return throwError(error);
        }));
    }

    public put(...request:any): Observable<any> {
        let [ path, body, query, options ] = this.setOptions(request);
        return this.http.put(this.query(this.env(path), query), body, options).pipe(catchError( error => {
          //this.message.error(error.error.message||error.message);
          this.isAuthenticated(error);
          return throwError(error);
        }));
    }

    public patch(...request:any): Observable<any> {
        let [ path, body, query, options ] = this.setOptions(request);
        return this.http.patch(this.query(this.env(path), query), body, options).pipe(catchError( error => {
          //this.message.error(error.error.message||error.message);
          this.isAuthenticated(error);
          return throwError(error);
        }));
    }

    public delete(...request:any): Observable<any> {
        let [ path, query, body, options ] = this.setOptions(request);
        return this.http.delete(this.query(this.env(path), query, body), options).pipe(catchError( error => {
          //this.message.error(error.error.message||error.message);
          this.isAuthenticated(error);
          return throwError(error);
        }));
    }

    public upload(path:string, query?:object, body?:any): Observable<any> {
        let fdata : FormData = new FormData(); fdata.append('file', body, body.name);
        return this.http.request(new HttpRequest('POST', this.query(this.env(path), query), fdata, {reportProgress: true})).pipe(catchError( error => {
          //this.message.error(error.error.message||error.message);
          this.isAuthenticated(error);
          return throwError(error);
        }));
    }

    public download(data:any, mimeType?:any, name?:string) {
        let link = document.createElement('a');
                   document.body.appendChild(link);
                   link.href = mimeType ? `data:${mimeType};base64,${data}` : data;
                   link.className = "hidden";
                   link.download = name||"download";
                   link.target="_blank";
                   link.click();
                   setTimeout(() => document.body.removeChild(link), 100);
        // return of(()=>{link.click();  setTimeout(() => document.body.removeChild(link), 100);});
    }

    public stream(path:string, query?:object, body?:object): Observable<any> {
        let link = document.createElement('a');
                   document.body.appendChild(link);
                   link.href = this.query(this.env(path), body, query);
                   link.className = "hidden";
                   link.download = "download";
                   link.target="_blank";
        return of(()=>{link.click();  setTimeout(() => document.body.removeChild(link), 100);});
    }

    public query(path:string, query?:any, body?:any) {
      if(Array.isArray(query)) {path += '/' + query.join('/'); query = undefined;}
      return query  || body ? path + '?'+ queryParse.stringify({...query, ...body}) : path;
    }

    private setOptions(request:any[]){
        let opt:any = {};
        let [ p1, p2, p3, p4 ] = request.map((req:any)=>{
            if(req && req.loading != undefined) { opt.loading = req.loading.toString(); delete req.loading; }
            if(req && req.caching != undefined) { opt.caching = req.caching.toString(); delete req.caching; }
            if(req && req.guid) { opt['Loading-ID'] = req.guid; delete req.guid; }
            if(req && req.Authorization) { req = {...req}; opt['Authorization'] = req.Authorization; delete req.Authorization;}
            return  req;
        });
        if(!opt['Authorization'] && this.user.token) opt['Authorization'] = this.user.token;
        return [p1, p2, p3, {...p4, headers:new HttpHeaders(p4 ? {...p4.headers, ...opt}: opt)}];
    }

    private env(path:string){
        return path.match(/^localhost@/) ? path.replace(/localhost@/, "") : (this.options&&this.options.host||"")+path;
    }

    /** Ovu funkcionalnost treba prebaciti na Gateway jer gateway ima sve informacije o sesiji,
    *  te moze lahko overridati response ako je useru istekla sesija.
    * @param response
    */
    isAuthenticated(res:any):any{
        /*console.log(/user\/logged-user/.test(res.url), res)
        console.log(res.status, res.status !== 401,  !this.user.token && !this.user.logged)*/
        if(res.status !== 401 || !this.user.token && !this.user.logged) return this.message.error((res.error.message||'').replace(/\n/gi,"<br />"), res.error.error, {enableHtml: true});
        this.user.logOut();
        this.message.warning('Your session has expired or you are not authorised to log in.');

        //res.status !== 401 || !this.user.token && !this.user.logged || this.user.logOut();
        //this.message.error((res.error.message||'').replace(/\n/gi,"<br />"), res.error.error, {enableHtml: true});
    }
}
