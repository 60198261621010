export const genGUID = function(){
  return 'Zx-'+'xxxxx-xxx-yxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  });
}

export const ZxJSON = function(load:any){
  return new Promise((resolve, reject)=>{
     try {resolve(typeof load === 'string' ? JSON.parse(load) :  typeof load === 'object' ? JSON.stringify(load) : load);}
     catch (e) {reject(e);}
  });
}

export enum MimeTypes { // Dodati na common reg
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  csv = 'text/csv',
  jpg = 'image/jpeg',
  jpeg = 'image/jpeg',
  pdf = 'application/pdf',
  xls = 'application/vnd.ms-excel',
  jasper = 'application/octet-stream',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  zip = 'application/zip',
  png = 'image/png',
  eml = 'file/rfc822'
}
