import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@portal/core';

import { NavigationComponent } from './navigation.component';
import { BreadcrumbComponent } from './breadcrumb.component'



@NgModule({
  declarations: [
    NavigationComponent,
    BreadcrumbComponent
  ],
  imports: [
    CommonModule,
    CoreModule
  ],
  exports: [
    NavigationComponent,
    BreadcrumbComponent
  ]
})
export class NavigationModule { }