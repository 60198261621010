<zx-preloader *ngIf="loaderService.isLoading | async"></zx-preloader>
<div [@triggerName]="prepareRoute(outlet)" class="router-wrapper">
	<router-outlet #outlet="outlet"></router-outlet>
</div>
<!-- <div class="app-notification" [ngClass]="{open: user.passwordExpire === 0 }">
	<div class="password-expired">
		<i class="fa-duotone fa-brake-warning"></i>
		<div>
			<h2>{{user.passwordExpiresIn}}!</h2>
			<div>{{'Please change your password.'|translate}}</div>
		</div>
	</div>
</div> -->
