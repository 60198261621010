import { Component, OnInit, Input } from '@angular/core';

import { ZxAction } from '@portal/core'

@Component({
  selector: 'breadcrumb',
  template: `
    <ul class="breadcrumb">
      <li *ngFor="let item of items" title="{{item.label}}" (click)="goTo(item.url)" [ngClass]="{action:item.action}"><i class="fa-solid fa-angles-right"></i> {{item.label}}</li>
    </ul>
  `,
  styleUrls: ['./navigation.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  @Input() items!:any[];

  constructor(private action:ZxAction) { }

  ngOnInit(): void {
  }

  goTo(item:any){

  }
}

