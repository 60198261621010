import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ZxAction {
	[x: string]: any;
	
  constructor() {}

	public set(index: string, item: any) { this[index] = item; return this; }

	public unset(...index:any) { index.map((i: string) => delete this[i]); }

	public apply(action: string, item: any) { !this[action] || this[action](item); return true; }
}
