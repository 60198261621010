import { Injectable, Inject, LOCALE_ID, ApplicationRef } from '@angular/core';
import { ZxApi } from '../api/zx-api.service';
import { ZxUserService } from '../user/zx-user.service';
import { ZxTranslate } from '../translate/zx-translate.service';
import { genGUID } from '../../helpers';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ZxTheme {
  public systemTheme:any = {name:'System theme', code:"SYSTEM_THEME", config:[
    {name:"Main color", code:"--main-color", r:29, g:53, b:87},
    {name:"Primary color", code:"--primary-color", r:230, g:57, b:70},
    {name:"Secondary color", code:"--secondary-color", r:69, g:123, b:157},
    {name:"Highlight color", code:"--highlight-color", r:168, g:218, b:220},
    {name:"Backligh colort", code:"--backlight-colo", r:228, g:228, b:228},
    {name:"Contrast color", code:"--contrast-color", r:35, g:36, b:43},
    {name:"Danger color", code:"--danger-color", r:166, g:2, b:13},
    {name:"Warning color", code:"--warning-color", r:192, g:69, b:3},
    {name:"Success color", code:"--success-color", r:0, g:102, b:36},
    {name:"Info color", code:"--info-color", r:76, g:89, b:103},
  ]} 
  public theme:any;

  constructor(private api: ZxApi,  @Inject('options') private options:any, private user:ZxUserService, private lang:ZxTranslate, private message:ToastrService)  {
    document.body.style.opacity = "0";
  }

  public save(callback?:any){
    this.api[this.theme.id?'put':'post']('/commonregistry/gui-configuration', { entity:this.get() } ).subscribe((respons:any)=> {
      this.message.success(this.lang.translate('Theme is saved successfully'));
      !callback||callback(respons.payload);
    });
  }

  public get(){
    return {...(this.theme||this.systemTheme), appId: this.options.appId, code:this.theme?.code||genGUID(), category: 'THEME'};
  }

  public default(){
  this.api.get('/commonregistry/gui-configuration', { appId: this.options.appId, category: 'THEME', type:"default"}).subscribe(respons=>{
      let [ entity ] = respons.payload;
      !entity||this.api.put('/commonregistry/gui-configuration', { entity:{...entity, type:undefined }}).subscribe();
      this.theme.code == 'SYSTEM_THEME'||this.api.put('/commonregistry/gui-configuration', { entity:{...this.theme, type:"default" }}).subscribe();
    });
  }

  public async load(){
    let query = { appId: this.options.appId, category: 'THEME', type:"default"}
    await this.api.get('/commonregistry/gui-configuration', query).toPromise().then((res:any)=> res.payload.length?this.set(res.payload[0]).apply():this.fadeIn(), (error)=>this.fadeIn());
  }

  public apply(){
    this.theme.config.map((color:any)=>{
      for(let i in color.gradient) {
        document.body.style.setProperty(i != '0' ? color.code+"-"+i: color.code, color.gradient[i]);  
      }
    });
    this.fadeIn();
  }

  public fadeIn(){
    document.body.style.transition="opacity 1s";
    document.body.style.opacity = "1";
  }

  public set(theme:any){
    this.theme = theme;
    return this;
  }

  public delete(id:any){
    this.api.delete('/commonregistry/gui-configuration', [id]).subscribe((respons:any)=> {
      this.message.success(this.lang.translate('Theme is deleted successfully'));
    });
  }

  themes(){
    return this.api.get('/commonregistry/gui-configuration', { appId: this.options.appId, category: 'THEME'});
  }
}
