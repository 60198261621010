import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { routeTransitionAnimations } from './route-transition-animations';

import { AppHeaderConfig } from '@portal/app-header'
import { environment } from '../environments/environment'

import { ZxApi, ZxUserService, UserModel, ZxAction } from '@portal/core';
import { NavigationModel } from '@portal/navigation';
import { Router } from '@angular/router';

import { Md5 } from 'ts-md5/dist/md5';
import {ZxPreloaderService} from "../../projects/portal/core/src/lib/service/preloader/zx-preloader.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations]
})
export class AppComponent {
  public appHeader:AppHeaderConfig = new AppHeaderConfig(environment);
  public menu:NavigationModel = new NavigationModel({code:'HOME_MENU', url:'assets/menu.json'});

  constructor(private api:ZxApi, public user:ZxUserService, private router:Router, public action:ZxAction, public loaderService: ZxPreloaderService){
    !this.user.token || this.user.logged || this.api.get('/user/logged-user').subscribe((response:UserModel)=> {
      this.user.add = response;
      //this.getRoles(this.user.id);
    });
    this.user.logOut = ()=>{
      this.api.get('/logout-user').subscribe(v=>{
        this.user.clearUser();
        this.router.navigate(['/']);
      });
    }
/*
    let query = "INSERT INTO role_permission (id, role_id, permission_id) VALUES ";
    query += [
      'CUSTOMER_CHANGE_ORDER',
'CUSTOMER_CHANGE_ORDER_CANCEL',
'CUSTOMER_CHANGE_ORDER_SAVE',
'CUSTOMER_CHANGE_ORDER_EXPORT',
'CUSTOMER_CHANGE_ORDER_ASSET_SEARCH',
'CUSTOMER_CHANGE_ORDER_CONFIGURATION',
'CUSTOMER_CHANGE_ORDER_APPOINTMENT_REZERVATION',
'CUSTOMER_CHANGE_ORDER_SUMMARY'
    ].map((v:string)=>{
      return `('${Md5.hashStr('COMMERCIAL'+v).toString()}', 'COMMERCIAL', '${v}')`
    }).join(',');

    console.log(query)*/
  }

  /*getRoles(id:string){
    this.api.get(`/customer/${id}/roles`).subscribe(res=> this.user.roles = res.roles);
  }*/
  prepareRoute(outlet: RouterOutlet): boolean {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animationState'];
  }

/*  toggleUserInfo(){
    this.action['toggleUserInfo'](true);
  }*/
}
