import { Injectable } from '@angular/core';
import { ZxTranslate } from '../translate/zx-translate.service';


@Injectable({
  providedIn: 'root'
})
export class ZxConfirmation {
    private el;
    private msg?:string;
    private title?:string;
    private confirm?:any;
    private callback?:any;
    private cancel?:any;
    private callbackCancel?:any;

    constructor(private translate:ZxTranslate)  {
        this.el = document.createElement("div");
        this.el.addEventListener('click', (e)=>{e.stopPropagation(); this.handleEvent(e, this.callback);});
        document.body.appendChild(this.el);
    }

    public info(...input:any){
        this.set(input);
        this.setTemplate('info', 'fal fa-info-circle');
    }

    public success(...input:any){
        this.set(input);
        this.setTemplate('success', 'fal fa-check-circle');
    }

    public warning(...input:any){
        this.set(input);
        this.setTemplate('warning', 'fal fa-exclamation-triangle');
    }

    public error(...input:any){
        this.set(input);
        this.setTemplate('error', 'fal fa-times');
    }

    private set(input:any){
        this.clear(); [this.msg, this.title, this.callback, this.callbackCancel, this.confirm, this.cancel] = input;
        [this.msg, this.title, this.confirm, this.cancel] = this.translate.list([this.msg, this.title, this.confirm, this.cancel]);
    }

    private clear(){return this.msg = this.title = this.callback = this.confirm = this.callbackCancel = this.cancel = undefined;}

    private handleEvent(e:any, callback:any){
      if (e.target.id === 'ZFFConfirmPopup') return false;
        if( e.target.id == "proceed") this.callback&&this.callback(e);
        if( e.target.id == "cancle") this.callbackCancel&&this.callbackCancel(e);
        this.el.innerHTML = "";
        return true;
    }

    private setTemplate(tmp:string, icon:string){
        this.el.innerHTML = `
            <div class="confir-over-all" id="ZFFConfirmPopup">
                <div class="confir-popup ${tmp}">
                    ${this.title?'<h4>'+this.title+'</h4>':''}
                    <p>${this.msg?this.msg:''}</p>
                    <div class="btn-action">
                        <button id="cancle" class="cancle invert">${this.cancel ? this.cancel : 'Cancel'}</button>
                        ${this.confirm?'<button id="proceed" class="proceed invert">'+this.confirm+'</button>':''}
                    </div>
                </div>
            </div>
        `;
    }

}
