import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'filterBy'})
export class FilterBy implements PipeTransform {

  transform(input:any, search:string, ...index:any) : any{
      return search ? input.filter((v:any)=> this.set(v, index).match(new RegExp(search, 'gi'))) : input;
  }

  private set(input:any, index:string[]):string {
    if (!index.length) return input;
    let value : string=""; index.map((v:string) => value += " " + input[v]); return value;
  }

}
