import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';

import { TooltipDirective } from './directive/tooltip.directive'
import { ZxInterceptor } from "./interceprtor/zx-interceptor"
import { Translate } from "./pipe/zx-translate.pipe"
import { FilterBy } from "./pipe/zx-filterby.pipe"
import { OrderBy } from "./pipe/zx-orderby.pipe"
import { SafePipe } from "./pipe/zx-sanitize.pipe";
import {ZxPreloaderService} from "./service/preloader/zx-preloader.service";
import {ZxPreloaderInterceptor} from "./interceprtor/zx-preloader.interceptor";
import {ZxPreloaderComponent} from "./components/preloader/zx-preloader.component";



/*import { ZxPreloaderService } from "./services/preloader/zx-preloader.service";
import { ZxPreloaderInterceptor } from "./interceprtors/zx-preloader.interceptor";
import { ZxPreloaderComponent } from './components/preloader/zx-preloader.component';*/


@NgModule({
  imports: [ CommonModule, HttpClientModule, ToastrModule.forRoot({timeOut: 5000})],
  declarations: [ Translate, FilterBy, OrderBy, SafePipe, TooltipDirective, ZxPreloaderComponent],
  exports: [ Translate, FilterBy, OrderBy,  SafePipe, TooltipDirective, ZxPreloaderComponent ],
  schemas: [],
  entryComponents: [],
  providers:[
    {
      provide : HTTP_INTERCEPTORS,
      useClass: ZxInterceptor,
      multi   : true,
    },
    ZxPreloaderService,
    { provide: HTTP_INTERCEPTORS,
      useClass: ZxPreloaderInterceptor,
      multi: true
    },
    ]
})
export class CoreModule {
    public static options(options:any): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                {
                    provide: 'options', // you can also use InjectionToken
                    useValue: options
                }
            ]
        };
    }
}
